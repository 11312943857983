var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Container',{attrs:{"title":_vm.$t('statements.accountStatements')}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('datepicker',{attrs:{"rules":[function (v) { return !!v || 'Field is required'; }],"label":_vm.$t('statements.startPeriod')},model:{value:(_vm.filters.from),callback:function ($$v) {_vm.$set(_vm.filters, "from", $$v)},expression:"filters.from"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('datepicker',{attrs:{"rules":_vm.endPeriodRules,"label":_vm.$t('statements.endPeriod')},model:{value:(_vm.filters.to),callback:function ($$v) {_vm.$set(_vm.filters, "to", $$v)},expression:"filters.to"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"mt-n3 mb-2",attrs:{"cols":"12"}},_vm._l((_vm.selectablePeriods),function(period){return _c('v-btn',{key:period.name,staticClass:"mr-2 mb-2",attrs:{"x-small":"","dark":_vm.filters.from == period.start && _vm.filters.to == period.end,"color":_vm.filters.from == period.start && _vm.filters.to == period.end ? '' : (_vm.$vuetify.theme.dark ? 'secondary darken-3' : 'grey lighten-2')},on:{"click":function($event){return _vm.setPeriod(period)}}},[_vm._v(" "+_vm._s(period.name)+" ")])}),1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"multiple":"","chips":"","items":_vm.transactionTypes,"item-text":"name","item-value":"code","rules":[function (v) { return v.length > 0 || _vm.$t('errors.fieldRequired'); }],"validate-on-blur":"","label":_vm.$t('statements.transactionType'),"required":"","clearable":"","open-on-clear":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
return [_c('v-expand-transition',[(index === 0)?_c('v-scroll-x-reverse-transition',{staticClass:"filter-chips",attrs:{"group":"","leave-absolute":"","tag":"div"}},_vm._l((_vm.filters.type),function(chip,chipIndex){return _c('div',{key:chip,staticClass:"d-inline-block"},[_c('v-chip',{attrs:{"close":""},on:{"click:close":function($event){return _vm.filters.type.splice(chipIndex, 1)}}},[_vm._v(" "+_vm._s(_vm.transactionTypes.find(function (type) { return type.code == chip; }).name)+" ")])],1)}),0):_vm._e()],1)]}}]),model:{value:(_vm.filters.type),callback:function ($$v) {_vm.$set(_vm.filters, "type", $$v)},expression:"filters.type"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticStyle:{"z-index":"1"},attrs:{"dark":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v(_vm._s(_vm.$t('statements.showBankStatement')))])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticStyle:{"float":"right","z-index":"0"},attrs:{"dark":"","loading":_vm.statsButtonLoading},on:{"click":_vm.downloadStats}},[_vm._v(" Invoice "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-arrow-collapse-down")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_vm._v(" "+_vm._s(_vm.$t('statements.incomeStatementDescription'))+" ")])],1)],1)],1)],1),(_vm.generated)?_c('v-card',{staticClass:"mt-10"},[_c('v-card-title',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.$t('statements.period'))+": "),_c('BaseDate',{attrs:{"date":_vm.filters.from}}),_vm._v(" – "),_c('BaseDate',{attrs:{"date":_vm.filters.to}})],1),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t('statements.accountId'))+": "+_vm._s(_vm.currentInvestAccount.reference_number)+" ")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.augmentedItems,"hide-default-footer":true,"loading":_vm.loading,"items-per-page":-1},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
var isMobile = ref.isMobile;
return [(_vm.includeBalance)?[(isMobile)?_c('div',{staticClass:"font-weight-bold text-center"},[_vm._v(" "+_vm._s(_vm.$t('statements.openingBalance'))+" "),_c('BaseDate',{attrs:{"date":_vm.openingBalance.date}}),_vm._v(": "),_c('BaseMoney',{attrs:{"amount":_vm.openingBalance.balance}})],1):_c('tr',{staticClass:"font-weight-bold"},[_c('td',{attrs:{"colspan":_vm.headers.length - 2}}),_c('td',{staticClass:"text-right",attrs:{"colspan":"1"}},[_vm._v(_vm._s(_vm.$t('statements.openingBalance'))+" "),_c('BaseDate',{attrs:{"date":_vm.openingBalance.date}})],1),_c('td',[_c('BaseMoney',{attrs:{"amount":_vm.openingBalance.balance}})],1)])]:_vm._e()]}},{key:"body.append",fn:function(ref){
var isMobile = ref.isMobile;
return [(_vm.includeBalance)?[(isMobile)?_c('div',{staticClass:"font-weight-bold text-center"},[_vm._v(" "+_vm._s(_vm.$t('statements.closingBalance'))+" "),_c('BaseDate',{attrs:{"date":_vm.closingBalance.date}}),_vm._v(": "),_c('BaseMoney',{attrs:{"amount":_vm.closingBalance.balance}})],1):_c('tr',{staticClass:"font-weight-bold"},[_c('td',{attrs:{"colspan":_vm.headers.length - 2}}),_c('td',{staticClass:"text-right",attrs:{"colspan":"1"}},[_vm._v(_vm._s(_vm.$t('statements.closingBalance'))+" "),_c('BaseDate',{attrs:{"date":_vm.closingBalance.date}})],1),_c('td',[_c('BaseMoney',{attrs:{"amount":_vm.closingBalance.balance}})],1)])]:_vm._e(),(isMobile)?_c('div',{staticClass:"font-weight-bold text-center"},[_vm._v(" "+_vm._s(_vm.$t('statements.debit'))+": "),_c('BaseMoney',{attrs:{"amount":-_vm.totalDebit,"signed":""}})],1):_c('tr',{staticClass:"font-weight-bold"},[_c('td',{attrs:{"colspan":_vm.headers.length - 2}}),_c('td',{staticClass:"text-right",attrs:{"colspan":"1"}},[_vm._v(_vm._s(_vm.$t('statements.debit')))]),_c('td',[_c('BaseMoney',{attrs:{"amount":-_vm.totalDebit,"signed":""}})],1)]),(isMobile)?_c('div',{staticClass:"font-weight-bold text-center"},[_vm._v(" "+_vm._s(_vm.$t('statements.credit'))+": "),_c('BaseMoney',{attrs:{"amount":_vm.totalCredit,"signed":""}})],1):_c('tr',{staticClass:"font-weight-bold"},[_c('td',{attrs:{"colspan":_vm.headers.length - 2}}),_c('td',{staticClass:"text-right",attrs:{"colspan":"1"}},[_vm._v(_vm._s(_vm.$t('statements.credit')))]),_c('td',[_c('BaseMoney',{attrs:{"amount":_vm.totalCredit,"signed":""}})],1)])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('BaseDate',{attrs:{"date":item.created_at}})]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"ma-0 my-1 d-flex align-center"},[_c('span',{staticClass:"pr-1"},[_vm._v(_vm._s(item.description))]),(item.related_loan)?[_c('router-link',{staticClass:"pr-1",attrs:{"to":{ name: 'Loan', params: { id: item.related_loan.id, back: true } }}},[_vm._v("("+_vm._s(_vm.$t('statements.loanId'))+": "+_vm._s(item.related_loan.reference_number)+")")]),_c('BaseLoanOriginatorChip',{staticClass:"mr-1",attrs:{"loan-originator":item.related_loan.loan_originator}}),(item.related_loan.autoinvest_strategy)?_c('BaseAutoInvestStrategyChip',{style:({'max-width': '10rem'}),attrs:{"strategy":item.related_loan.autoinvest_strategy}}):_vm._e()]:_vm._e()],2),(item.is_buyback)?_c('span',{staticClass:"primary--text text--lighten-5"},[_vm._v(" – "+_vm._s(_vm.$t('statements.buyback'))+" ")]):_vm._e()]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('BaseMoney',{attrs:{"amount":item.amount,"signed":""}})]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('BaseMoney',{attrs:{"amount":item.balance}})]}}],null,true)})],1),_c('v-card-actions',[(_vm.items.length)?_c('vue-excel-xlsx',{attrs:{"data":_vm.spreadsheetData,"columns":_vm.spreadsheetColumns}},[_c('v-btn',{staticClass:"ma-2",attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.$t('statements.download'))+" "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-arrow-collapse-down")])],1)],1):_vm._e(),_c('v-spacer')],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }