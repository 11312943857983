<template>
  <Container :title="$t('statements.accountStatements')">
    <v-card outlined>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" lg="6">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" md="6">
                    <datepicker
                      v-model="filters.from"
                      :rules="[(v) => !!v || 'Field is required']"
                      :label="$t('statements.startPeriod')"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <datepicker
                      v-model="filters.to"
                      :rules="endPeriodRules"
                      :label="$t('statements.endPeriod')"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" class="mt-n3 mb-2">
                    <v-btn
                      v-for="period in selectablePeriods"
                      :key="period.name"
                      x-small
                      :dark="filters.from == period.start && filters.to == period.end"
                      :color="filters.from == period.start && filters.to == period.end ? '' : ($vuetify.theme.dark ? 'secondary darken-3' : 'grey lighten-2')"
                      class="mr-2 mb-2"
                      @click="setPeriod(period)"
                    >
                      {{ period.name }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-select
                      v-model="filters.type"
                      multiple
                      chips
                      :items="transactionTypes"
                      item-text="name"
                      item-value="code"
                      :rules="[(v) => v.length > 0 || $t('errors.fieldRequired')]"
                      validate-on-blur
                      :label="$t('statements.transactionType')"
                      required
                      clearable
                      open-on-clear
                    >
                      <template #selection="{ index }">
                        <v-expand-transition>
                          <v-scroll-x-reverse-transition v-if="index === 0" group leave-absolute tag="div" class="filter-chips">
                            <div v-for="(chip, chipIndex) in filters.type" :key="chip" class="d-inline-block">
                              <v-chip close @click:close="filters.type.splice(chipIndex, 1)">
                                {{ transactionTypes.find(type => type.code == chip).name }}
                              </v-chip>
                            </div>
                          </v-scroll-x-reverse-transition>
                        </v-expand-transition>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="6">
                    <v-btn dark style="z-index: 1" @click="submit()">{{ $t('statements.showBankStatement') }}</v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn dark style="float:right; z-index:0" :loading="statsButtonLoading" @click="downloadStats">
                      Invoice
                      <v-icon small right>mdi-arrow-collapse-down</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
            <v-col cols="12" lg="6">
              {{ $t('statements.incomeStatementDescription') }}
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-card v-if="generated" class="mt-10">
      <v-card-title class="d-block">
        {{ $t('statements.period') }}: <BaseDate :date="filters.from"/> – <BaseDate :date="filters.to"/>
      </v-card-title>
      <v-card-subtitle>
        {{ $t('statements.accountId') }}: {{ currentInvestAccount.reference_number }}
      </v-card-subtitle>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="augmentedItems"
          :hide-default-footer="true"
          :loading="loading"
          :items-per-page="-1"
          class="elevation-0"
        >
          <template #[`body.prepend`]="{ isMobile }">
            <template v-if="includeBalance">
              <div v-if="isMobile" class="font-weight-bold text-center">
                {{ $t('statements.openingBalance') }} <BaseDate :date="openingBalance.date"/>:
                <BaseMoney :amount="openingBalance.balance"/>
              </div>
              <tr v-else class="font-weight-bold">
                <td :colspan="headers.length - 2"/>
                <td colspan="1" class="text-right">{{ $t('statements.openingBalance') }} <BaseDate :date="openingBalance.date"/></td>
                <td><BaseMoney :amount="openingBalance.balance"/></td>
              </tr>
            </template>
          </template>
          <template #[`body.append`]="{ isMobile }">
            <template v-if="includeBalance">
              <div v-if="isMobile" class="font-weight-bold text-center">
                {{ $t('statements.closingBalance') }} <BaseDate :date="closingBalance.date"/>:
                <BaseMoney :amount="closingBalance.balance"/>
              </div>
              <tr v-else class="font-weight-bold">
                <td :colspan="headers.length - 2"/>
                <td colspan="1" class="text-right">{{ $t('statements.closingBalance') }} <BaseDate :date="closingBalance.date"/></td>
                <td><BaseMoney :amount="closingBalance.balance"/></td>
              </tr>
            </template>
            <div v-if="isMobile" class="font-weight-bold text-center">
              {{ $t('statements.debit') }}:
              <BaseMoney :amount="-totalDebit" signed/>
            </div>
            <tr v-else class="font-weight-bold">
              <td :colspan="headers.length - 2"/>
              <td colspan="1" class="text-right">{{ $t('statements.debit') }}</td>
              <td><BaseMoney :amount="-totalDebit" signed/></td>
            </tr>
            <div v-if="isMobile" class="font-weight-bold text-center">
              {{ $t('statements.credit') }}:
              <BaseMoney :amount="totalCredit" signed/>
            </div>
            <tr v-else class="font-weight-bold">
              <td :colspan="headers.length - 2"/>
              <td colspan="1" class="text-right">{{ $t('statements.credit') }}</td>
              <td><BaseMoney :amount="totalCredit" signed/></td>
            </tr>
          </template>
          <template #[`item.created_at`]="{ item }">
            <BaseDate :date="item.created_at"/>
          </template>
          <template #[`item.description`]="{ item }">
            <v-row class="ma-0 my-1 d-flex align-center">
              <span class="pr-1">{{ item.description }}</span>
              <template v-if="item.related_loan">
                <router-link class="pr-1" :to="{ name: 'Loan', params: { id: item.related_loan.id, back: true } }">({{ $t('statements.loanId') }}: {{ item.related_loan.reference_number }})</router-link>
                <BaseLoanOriginatorChip class="mr-1" :loan-originator="item.related_loan.loan_originator"/>
                <BaseAutoInvestStrategyChip v-if="item.related_loan.autoinvest_strategy" :style="{'max-width': '10rem'}" :strategy="item.related_loan.autoinvest_strategy"/>
              </template>
            </v-row>
            <span v-if="item.is_buyback" class="primary--text text--lighten-5">
              – {{ $t('statements.buyback') }}
            </span>
          </template>
          <template #[`item.amount`]="{ item }">
            <BaseMoney :amount="item.amount" signed/>
          </template>
          <template #[`item.balance`]="{ item }">
            <BaseMoney :amount="item.balance"/>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <vue-excel-xlsx
          v-if="items.length"
          :data="spreadsheetData"
          :columns="spreadsheetColumns"
        >
          <v-btn dark class="ma-2">
            {{ $t('statements.download') }}
            <v-icon small right>mdi-arrow-collapse-down</v-icon>
          </v-btn>
        </vue-excel-xlsx>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </Container>
</template>

<script>
import { formatISO, addDays, startOfToday, startOfWeek, lastDayOfWeek, startOfMonth, lastDayOfMonth } from 'date-fns'

import Vue from 'vue'

export default Vue.extend({
  name: "Statements",
  data() {
    return {
      endPeriodRules: [
        (v) => !!v || this.$t('errors.fieldRequired'),
        () => !!this.filters.from && (new Date(this.filters.to).getTime() >= new Date(this.filters.from).getTime()) || this.$t('statements.wrongPeriod'),
      ],
      valid: false,
      initialized: false,
      generated: false,
      filters: { to: '', from: '', type: [] },
      items: [],
      openingBalance: null,
      closingBalance: null,
      totalDebit: null,
      totalCredit: null,
      loading: false,
      includeBalance: false,
      statsButtonLoading: false,
    }
  },
  computed: {
    selectablePeriods() {
      // TODO what to do when the day rolls over?
      const format = date => formatISO(date, { representation: 'date' })
      const today          = startOfToday()
      const yesterday      = addDays(today, -1)
      const weekStart      = startOfWeek(today, { weekStartsOn: 1 })
      const weekEnd        = lastDayOfWeek(weekStart, { weekStartsOn: 1 })
      const lastWeekStart  = startOfWeek(addDays(weekStart, -1), { weekStartsOn: 1 })
      const lastWeekEnd    = lastDayOfWeek(lastWeekStart, { weekStartsOn: 1 })
      const monthStart     = startOfMonth(today)
      const monthEnd       = lastDayOfMonth(today)
      const lastMonthStart = startOfMonth(addDays(monthStart, -1))
      const lastMonthEnd   = lastDayOfMonth(lastMonthStart)

      return [
        // TODO translations
        { name: this.$t('statements.filters.today'),      start: format(today),          end: format(today)        },
        { name: this.$t('statements.filters.yesterday'),  start: format(yesterday),      end: format(yesterday)    },
        { name: this.$t('statements.filters.thisWeek'),   start: format(weekStart),      end: format(weekEnd)      },
        { name: this.$t('statements.filters.lastWeek'),   start: format(lastWeekStart),  end: format(lastWeekEnd)  },
        { name: this.$t('statements.filters.thisMonth'),  start: format(monthStart),     end: format(monthEnd)     },
        { name: this.$t('statements.filters.lastMonth'),  start: format(lastMonthStart), end: format(lastMonthEnd) },
      ]
    },
    transactionTypes() {
      let transaction_types = [
        { code: "Investment",         name: this.$t('statements.investmentToPrimaryMarket'),
          positive_name: this.$t('statements.investmentToPrimaryMarketRejected') },
        { code: "Principal",          name: this.$t('statements.principalReceived')         },
        { code: "Interest",           name: this.$t('statements.interestReceived')          },
        { code: "Penalty",            name: this.$t('statements.penaltyInterestReceived')   },
        { code: "BankTransaction",    name: this.$t('statements.deposit')                   },
        { code: "ReferralBonus",      name: this.$t('statements.referralBonus')             },
        { code: "ReferralReward", name: this.$t('statements.referralReward') },
        { code: "PaymentCardDeposit", name: this.$t('statements.stripePayments'),
          positive_name: this.$t('statements.paymentCardDeposit'),
          negative_name: this.$t('statements.refundCardDeposit') },
        { code: "WithdrawalRequest",  name: this.$t('statements.withdrawal'),
          positive_name: this.$t('statements.withdrawalRejected')        },
      ]
      if (this.user.imported_from_old_monestro) {
        transaction_types.push(
          { code: "OldMonestroUserBalance",            name: this.$t('statements.oldMonestroBalance')            },
          { code: "OldMonestroUserRemainingPrincipal", name: this.$t('statements.oldMonestroRemainingPrincipal') },
        )
      }
      return transaction_types
    },
    spreadsheetColumns: function() {
      return [
        ...this.headers,
        { text: this.$t('statements.loanId'), value: 'loan_id' },
        { text: this.$t('statements.autoInvestStrategyName'), value: 'autoinvest_strategy_name' },
      ].map(h => {
        return { label: h.text, field: h.value }
      })
    },
    user: function() {
      return this.$auth.user()
    },
    currentInvestAccount: function() {
      return this.$store.state.account
    },
    headers: function() {
      const headers = [
        { text: this.$t('tableHeaders.date'),            align: 'start', sortable: false, value: 'created_at'       },
        { text: this.$t('tableHeaders.transactionID'),   align: 'start', sortable: false, value: 'id'               },
        { text: this.$t('tableHeaders.transactionType'), align: 'start', sortable: false, value: 'description'      },
        { text: this.$t('tableHeaders.amount'),          align: 'start', sortable: false, value: 'amount'           },
      ]
      if (this.includeBalance) {
        headers.push(
          { text: this.$t('statements.balance'),       align: 'start', sortable: false, value: 'balance'          },
        )
      }
      return headers
    },
    augmentedItems() {
      return this.items.map(item => {
        // XXX ↓ This is not too slow (we're limited to 8 iterations tops for each entry), but maybe
        //       it is a good idea to use a lookup dict.
        const type = this.transactionTypes.find(type => type.code == item.transaction_type)
        const amount = item.transaction_leg.credit - item.transaction_leg.debit
        const description = amount > 0 && type.positive_name ? type.positive_name : type.negative_name || type.name
        return {
          ...item,
          amount: (amount).toFixed(2),
          loan_id: item.related_loan?.id,
          autoinvest_strategy_name: item.related_loan?.autoinvest_strategy?.strategy_name,
          description,
        }
      })
    },
    spreadsheetData() {
      if (!this.includeBalance) { return this.augmentedItems }

      return [
        { 'balance': this.openingBalance?.balance, created_at: this.openingBalance?.date, 'description': 'OPENING BALANCE' },
        ...this.augmentedItems,
        { 'balance': this.closingBalance?.balance, created_at: this.closingBalance?.date, 'description': 'CLOSING BALANCE' },
        { 'amount': this.totalDebit, 'description': 'Total debit' },
        { 'amount': this.totalCredit, 'description': 'Total credit' },
      ]
    },
  },
  watch: {
    filters: {
      deep: true,
      // handler() { this.submit() },
    },
    currentInvestAccount: {
      handler(value, oldValue) {
        if (value?.id != oldValue?.id) {
          // this.submit()
        }
      },
    },
    valid: {
      handler(value) {
        if (value && !this.initialized) {
          // XXX This is a bit of a hack in an attempt to automatically
          // load the statement the first time without requiring the user
          // to press the button. mounted, created and other immediate
          // watchers are too early. Keep in mind that investment account
          // can also be unset. When changing this, be careful not to cause
          // double requests.
          // this.submit()
        }
      },
    },
  },
  created() {
    this.filters.type = this.transactionTypes.filter(t => !t.code.includes('OldMonestro')).map(t => t.code)
    this.setPeriod(this.selectablePeriods[2])
  },
  // activated() {
  //   this.submit()
  // },
  methods: {
    setPeriod(period) {
      this.filters.from = period.start
      this.filters.to   = period.end
    },
    submit() {
      let filter_items = this.filters.type.join(',')
      if (!this.user.imported_from_old_monestro) {
        filter_items += ',OldMonestroUserBalance,OldMonestroUserRemainingPrincipal'
      }
      if (this.currentInvestAccount.id && this.$refs.form.validate()) {
        this.initialized = true
        this.generated = true
        this.loading = true
        this.includeBalance = this.filters.type.length >= this.transactionTypes.length - 2  // - 2 because of fake OldMonestro types
        this.$http.get(`/statements/?from=${this.filters.from}&to=${this.filters.to}&investment_account=${this.currentInvestAccount.id}&types=${filter_items}`).then((response) => {
          this.items = response.data.results
          this.openingBalance = response.data.opening_balance
          this.closingBalance = response.data.closing_balance
          this.totalDebit     = response.data.total_debit
          this.totalCredit    = response.data.total_credit
          this.loading = false
        }).catch( () => this.loading = false)
      } else {
        this.items = []
        this.openingBalance = null
        this.closingBalance = null
        this.generated = false
      }
    },
    downloadStats() {
      this.statsButtonLoading = true
      this.$http.get(`/statements/stats/?from=${this.filters.from}&to=${this.filters.to}&investment_account=${this.currentInvestAccount.id}`, { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `stats ${this.filters.from}-${this.filters.to}`
          link.click()
          URL.revokeObjectURL(link.href)
          this.statsButtonLoading = false
        })
        .catch( () =>{
          this.$store.commit('pushMessage', {
            icon: 'mdi-robot-dead',
            text: "Can't get invoice",
          })
          this.statsButtonLoading = false
        })
    },
  },
})
</script>

<style>
.filter-chips .scroll-x-reverse-transition-leave-active {
  display: none !important;
}
</style>
